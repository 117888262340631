/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import TextField from "@atlaskit/textfield";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Checkbox } from "@atlaskit/checkbox";
import Select from "@atlaskit/select";
import toBoolean from "../../utils/to-boolean";
import EditorRemoveIcon from "@atlaskit/icon/glyph/editor/remove";
import { v4 as uuidv4 } from "uuid";
import TextArea from "@atlaskit/textarea";
import useGlobalConfig from "../../hooks/use-global-config";
import { useTranslation } from "react-i18next";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import Ajv from "ajv";
import SelectRoles from "../roles/select-roles";

export default function EditField({ field, onClose, onAdded, fields, documentTypeId }) {
    const { t } = useTranslation();
    const { run, isPending, error, isError: isCreatingError } = useAsync();
    const [name, setName] = useState(field.name);
    const [type, setType] = useState(() => {
        const f = fields.find(i => i.id === field?.field?.id);
        if (f) {
            return {
                label: f.type,
                id: f.id,
                value: f.id,
            };
        }
    });
    const [label, setLabel] = useState(field?.label);
    const [position, setPosition] = useState(field?.position);
    const [validationRules, setValidationRules] = useState(() => field?.validation_rules ? JSON.stringify(field?.validation_rules) : "[]");
    const [required, setRequired] = useState(toBoolean(field?.required));
    const [hidden, setHidden] = useState(toBoolean(field?.hidden));
    const [quickForm, setQuickForm] = useState(toBoolean(field?.quick_form));
    const [readOnly, setReadOnly] = useState(toBoolean(field?.read_only));
    const [mustHave, setMustHave] = useState(toBoolean(field?.must_have));
    const [workflow, setWorkflow] = useState(toBoolean(field?.workflow));
    const [help, setHelp] = useState(field?.help);
    const [assured, setAssured] = useState(false);
    const [options, setOptions] = useState(() => {
        if (Array.isArray(field?.options)) {
            return field?.options ?? [];
        }
        return [];
    });
    const [rawOptions, setRawOptions] = useState(() => {
        if (Array.isArray(field?.options)) {
            return field?.options?.[0] || "";
        }
        if (typeof field?.options === "object") {
            return JSON.stringify(field?.options);
        }

        return field?.options?.[0] || "";
    });
    const [selectOptions, setSelectOptions] = useState(() => {
        if (typeof field?.select_options === "object") {
            return JSON.stringify(field?.select_options);
        }

        return field?.select_options ?? "{}";
    });
    const [visibleRoles, setVisibleRoles] = useState(() => {
        return field?.visible_roles ?? [];
    });
    const [editableRoles, setEditableRoles] = useState(() => {
        return field?.editable_roles ?? [];
    });




    const [parsedConfigSelectOptions, setParsedConfigSelectOptions] = useState(() => {
        try {
            return JSON.parse(selectOptions);
        } catch (e) {
            return "";
        }
    });

    useEffect(() => {
        try {
            setParsedConfigSelectOptions(JSON.parse(selectOptions));
        } catch (e) {
            console.log(e);
        }
    }, [selectOptions]);




    const [parsedConfigRawOptions, setParsedConfigRawOptions] = useState(() => {
        try {
            return JSON.parse(rawOptions);
        } catch (e) {
            return "";
        }
    });

    useEffect(() => {
        try {
            setParsedConfigRawOptions(JSON.parse(rawOptions));
        } catch (e) {
            console.log(e);
        }
    }, [rawOptions]);



    const [parsedConfigValidationRules, setParsedConfigValidationRules] = useState(() => {
        try {
            return JSON.parse(validationRules);
        } catch (e) {
            return "";
        }
    });

    useEffect(() => {
        try {
            setParsedConfigValidationRules(JSON.parse(validationRules));
        } catch (e) {
            console.log(e);
        }
    }, [validationRules]);




    // const [parsedVisibleRoles, setParsedVisibleRoles] = useState(() => {
    //     try {
    //         return JSON.parse(visibleRoles);
    //     } catch (e) {
    //         return visibleRoles;
    //     }
    // });

    // useEffect(() => {
    //     try {
    //         setParsedConfigValidationRules(JSON.parse(validationRules));
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }, [validationRules]);




    useEffect(() => {
        if (typeof field?.options === "object") {
            setRawOptions(JSON.stringify(field?.options));
        }

        return setRawOptions(field?.options?.[0] || "");
    }, [field?.id]);

    const { config: fieldsMapConfig } = useGlobalConfig("fields_map");

    const setOptionName = (id, name) => setOptions(opt => opt.map(o => {
        if (o.id === id) return { ...o, name };
        return o;
    }));

    const setOptionValue = (id, value) => setOptions(opt => opt.map(o => {
        if (o.id === id) return { ...o, value };
        return o;
    }));

    const addNewRow = () => setOptions(opt => ([
        ...opt,
        { id: uuidv4(), name: "", value: "" },
    ]));

    const removeOption = option => setOptions(opt => opt.filter(o => o?.id !== option?.id));

    const footer = (props) => (
        <ModalFooter showKeyline={props.showKeyline}>
            <ButtonGroup>
                <Button appearance="primary" type="button" isLoading={isPending} onClick={handleSubmit}>
                    {t("admin_edit_field_save")}
                </Button>
                <Button type="button" isDisabled={isPending} onClick={onClose}>
                    {t("admin_edit_field_cancel")}
                </Button>
            </ButtonGroup >
        </ModalFooter>
    );

    const handleSubmit = () => {
        const formData = {
            document_type_id: documentTypeId,
            field_id: type?.value,
            name,
            label,
            required,
            hidden,
            quick_form: quickForm,
            read_only: readOnly,
            must_have: mustHave,
            workflow,
            position,
            help,
            validation_rules: JSON.parse(validationRules),
            editable_roles: editableRoles,
            visible_roles: visibleRoles,
        };

        run(apiClient(`document-type-fields/${field.id}`, { method: "PATCH", data: formData }))
            .then(response => {
                if (type?.value === fieldsMapConfig?.selectId || type?.value === fieldsMapConfig?.multiSelectId) {
                    run(apiClient(`document-type-field/${field?.id}/options`, {
                        method: "PATCH", data: {
                            options,
                            selectOptions,
                        }
                    }))
                        .then(() => {
                            onAdded(response);
                            onClose();
                        })
                        .catch(() => {
                            toast.error(t("admin_edit_field_edit_option_error"));
                        });

                } else {
                    apiClient(`document-type-field/${field?.id}/options/raw`, { method: "PATCH", data: rawOptions })
                        .then(() => {
                            onAdded(response);
                            onClose();
                        })
                        .catch(() => {
                            toast.error(t("admin_edit_field_edit_option_error"));
                        });
                }
            })
            .catch(() => {
                toast.error(t("admin_edit_field_edit_error"));
            });
    };

    return <ModalTransition>
        <Modal
            onClose={onClose}
            heading={t("admin_edit_field_heading")}
            scrollBehavior="inside-wide"
            components={{
                Footer: footer,
            }}
        >
            {isCreatingError && <SectionMessageWrapper>
                <SectionMessage title={t("admin_edit_field_error_heading")} appearance="error">
                    <p>{error?.message}</p>
                    {error?.errors && <ul>
                        {Object.keys(error.errors).map(key => (
                            <li key={key}>{error.errors[key]}</li>
                        ))}
                    </ul>}
                </SectionMessage>
            </SectionMessageWrapper>}
            <Field name="type" label={t("admin_edit_field_type")} isRequired autoFocus
                isDisabled={isPending || !assured}>
                {({ fieldProps }) => (<Select
                    {...fieldProps}
                    value={type}
                    onChange={e => setType(e)}
                    options={fields.map(i => ({ label: i.type, value: i.id }))}
                />)}
            </Field>
            {!assured && <small>
                <div>The type should not be changed after the field has been created.</div>
                <div>If you know what you&apos;re doing- <a href="#" onClick={(e) => {
                    e.preventDefault();
                    setAssured(true);
                }}>click here</a>.</div>
            </small>}
            <Field name="name" label={t("admin_edit_field_name")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={name} onChange={e => setName(e.target.value)} isInvalid={error?.errors?.name} autoComplete={false} />}
            </Field>
            <Field name="label" label={t("admin_edit_field_label")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={label} onChange={e => setLabel(e.target.value)} isInvalid={error?.errors?.label} autoComplete={false} />}
            </Field>
            {(type?.value === fieldsMapConfig?.selectId || type?.value === fieldsMapConfig?.multiSelectId) && <OptionsWrapper>
                <Field name="options" label="" isDisabled={isPending}>
                    {() => (
                        <>
                            {options?.map(option => <OptionWrapper key={option.id}>
                                <OptionColumn>
                                    <Field name="option-name" label={t("admin_edit_field_option_name")} isDisabled={isPending}>
                                        {() => <TextField value={option.name} onChange={e => setOptionName(option.id, e.target.value)} />}
                                    </Field>
                                </OptionColumn>
                                <OptionColumn>
                                    <Field name="option-value" label={t("admin_edit_field_option_value")} isDisabled={isPending}>
                                        {() => <TextField value={option.value} onChange={e => setOptionValue(option.id, e.target.value)} />}
                                    </Field>
                                </OptionColumn>
                                <RemoveIconWrapper>
                                    <Button iconBefore={<EditorRemoveIcon />} onClick={() => removeOption(option)} />
                                </RemoveIconWrapper>
                            </OptionWrapper>)}
                            <AddNewRow>
                                <Button appearance="primary" onClick={addNewRow}>
                                    {t("admin_edit_field_add_option")}
                                </Button>
                            </AddNewRow>
                        </>
                    )}
                </Field>
            </OptionsWrapper>}
            {(type?.value !== fieldsMapConfig?.selectId && type?.value !== fieldsMapConfig?.multiSelectId) && <Field name="rawOptions" label={t("admin_edit_field_raw_options")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <Editor
                    mode={Editor.modes.code}
                    theme="ace/theme/tomorrow_night_blue"
                    ajv={Ajv({ allErrors: true, verbose: true })}
                    value={parsedConfigRawOptions}
                    onChange={e => {
                        try {
                            setRawOptions(JSON.stringify(e));
                        } catch (e) {
                            console.log(e);
                        }
                    }}
                />}
            </Field>}
            {/* {(type?.value === fieldsMapConfig?.selectId || type?.value === fieldsMapConfig?.multiSelectId) && <Field name="rawOptions" label={t("admin_edit_field_raw_options")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextArea
                    {...fieldProps}
                    isMonospaced
                    value={selectOptions}
                    onChange={e => setSelectOptions(e?.target?.value)}
                    minimumRows={5}
                />}
            </Field>} */}
            {(type?.value === fieldsMapConfig?.selectId || type?.value === fieldsMapConfig?.multiSelectId) && <Field name="rawOptions" label={t("admin_edit_field_raw_options")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <Editor
                    value={parsedConfigSelectOptions}
                    mode={Editor.modes.code}
                    theme="ace/theme/tomorrow_night_blue"
                    ajv={Ajv({ allErrors: true, verbose: true })}
                    onChange={e => {
                        try {
                            setSelectOptions(JSON.stringify(e));
                        } catch (e) {
                            console.log(e);
                        }
                    }}
                />}
            </Field>}

            <Field name="position" label={t("admin_edit_field_position")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextField type="number" {...fieldProps} value={position} onChange={e => setPosition(e.target.value)} isInvalid={error?.errors?.position} autoComplete={false} />}
            </Field>
            <Field id="required" name="required" label="">
                {() => (
                    <Checkbox label={t("admin_edit_field_required")} isChecked={required} onChange={() => setRequired(v => !v)} isDisabled={isPending} />
                )}
            </Field>
            <Field id="mustHave" name="mustHave" label="">
                {() => (
                    <Checkbox label={t("admin_create_field_must_have")} isChecked={mustHave} onChange={() => setMustHave(v => !v)} isDisabled={isPending} />
                )}
            </Field>
            <Field id="hidden" name="hidden" label="">
                {() => (
                    <Checkbox label={t("admin_edit_field_hidden")} isChecked={hidden} onChange={() => setHidden(v => !v)} isDisabled={isPending} />
                )}
            </Field>
            <Field id="quickForm" name="quickForm" label="">
                {() => (
                    <Checkbox label={t("admin_edit_field_quick_form")} isChecked={quickForm} onChange={() => setQuickForm(v => !v)} isDisabled={isPending} />
                )}
            </Field>
            <Field id="readOnly" name="readOnly" label="">
                {() => (
                    <Checkbox label={t("admin_edit_field_read_only")} isChecked={readOnly} onChange={() => setReadOnly(v => !v)} isDisabled={isPending} />
                )}
            </Field>
            <Field id="workflow" name="workflow" label="">
                {() => (
                    <Checkbox label={t("admin_edit_field_worfklow_filled")} isChecked={workflow} onChange={() => setWorkflow(v => !v)} isDisabled={isPending} />
                )}
            </Field>
            <Field id="help" name="help" label={t("admin_create_field_help")}>
                {() => (
                    <TextArea onChange={e => setHelp(e.target.value)} value={help} isDisabled={isPending} />
                )}
            </Field>
            {/* <Field id="validationRules" name="validationRules" label={t("admin_create_field_validation_rules")}>
                {() => (
                    <TextArea onChange={e => setValidationRules(e.target.value)} value={validationRules} isDisabled={isPending}
                        minimumRows={5} isMonospaced />
                )}
            </Field> */}
            <Field id="validationRules" name="validationRules" label={t("admin_create_field_validation_rules")}>
                {() => (
                    <Editor
                        value={parsedConfigValidationRules}
                        mode={Editor.modes.code}
                        theme="ace/theme/tomorrow_night_blue"
                        ajv={Ajv({ allErrors: true, verbose: true })}
                        onChange={e => {
                            try {
                                setValidationRules(JSON.stringify(e));
                            } catch (e) {
                                console.log(e);
                            }
                        }}
                    />
                )}
            </Field>
            <Field id="visibleRoles" name="visibleRoles" label={t("admin_create_field_visible_roles")}>
                {() => (
                    <SelectRoles ids={visibleRoles} onChange={ids => {
                        setVisibleRoles(ids?.map(i => i?.value));
                    }} />
                )}
            </Field>
            <Field id="editableRoles" name="editableRoles" label={t("admin_create_field_editable_roles")}>
                {() => (
                    <SelectRoles ids={editableRoles} onChange={ids => {
                        setEditableRoles(ids?.map(i => i?.value));
                    }} />
                )}
            </Field>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </Modal>
    </ModalTransition>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 0px;
    font-size: 13px;
`;

const OptionsWrapper = styled.div`
    padding: 5px 20px 20px;
    background: rgba(0,0,0,0.02);
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 15px;
`;

const OptionWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const OptionColumn = styled.div`
    width: 50%;
    padding-right: 10px;
    padding-bottom: 0px;
    margin-bottom: 0px;
`;

const AddNewRow = styled.div`
    padding: 20px 0;
`;

const RemoveIconWrapper = styled.div`
    margin: 30px 5px 0;
`;