import { format } from "date-fns";
import { useCallback, useEffect } from "react";
import apiClient from "../api/api-client";
import useAsync from "../hooks/use-async";

export default function useCalendarEvents(startDate, endDate, xId, yId, xFilters = null, yFilters = null) {
    const { data: resources, isIdle: xIsIdle, isPending: xIsPending, isError: xIsError, isSuccess: xIsSuccess, run: xRun } = useAsync();
    const { data: events, isIdle: yIsIdle, isPending: yIsPending, isError: yIsError, isSuccess: yIsSuccess, run: yRun } = useAsync();

    useEffect(() => {
        if (!startDate || !endDate || !xId || !yId) return;
        reload();
    }, [startDate, endDate, xId, yId]);

    useEffect(() => {
        const params = {
            document_type_id: xId,
            per_page: 10000,
            loadLinks: true,
            condition: "AND",
            filters: [
                ...(xFilters ?? []),
            ],
        };
        xRun(apiClient(`workflow/document-type/${xId}/documents`, { method: "POST", data: params })).catch(() => { });
    }, [xId]);

    const reload = useCallback(() => {
        const params = {
            document_type_id: yId,
            condition: "AND",
            filters: [
                ...(yFilters ?? []),
                {
                    name: "start_date",
                    operator: "is_before",
                    value: format(endDate, "yyyy-MM-dd"),
                },
                {
                    name: "end_date",
                    operator: "is_after",
                    value: format(startDate, "yyyy-MM-dd"),
                },
            ],
            links: [
                // { operator: "=", document_id: xId }
            ],
            per_page: 10000,
        };
        yRun(apiClient(`workflow/document-type/${yId}/documents?per_page=10000&loadLinks=true`, { method: "POST", data: params })).catch(() => { });
    });

    return {
        resources: resources?.data || [],
        events: events?.data || [],
        isPending: xIsIdle || yIsIdle || xIsPending || yIsPending,
        isError: xIsError || yIsError,
        isSuccess: xIsSuccess && yIsSuccess,
        reload,
    };
}