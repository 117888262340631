import React, { useEffect, useMemo, useRef, useState } from "react";
import { Gantt } from "./index.tsx";
import "gantt-task-react/dist/index.css";
import useDocumentsList from "../../hooks/use-documents-list";
import Spinner from "../ui/spinner";
import i18next from "i18next";
import { format } from "date-fns";
import apiClient from "../../api/api-client";
import useAsync from "../../hooks/use-async";
import { toast } from "react-toastify";
import EditEventModal from "../documents/edit-event-modal";
import Select from "@atlaskit/select";
import Drawer from "../ui/drawer";
import styles from "./task-list-header.module.css";
import { FiltersArea } from "../../pages/space.js";
import Filters from "../filters/filters.js";
import useAvailableDocumentTypes from "../../hooks/use-available-document-types.js";
import Button from "@atlaskit/button/custom-theme-button";
import Badge from "@atlaskit/badge";
import { useTranslation } from "react-i18next";
import useDocumentStatuses from "../../hooks/use-document-statuses.js";
import styled from "styled-components";
import useDocumentTypeFilters from "../../hooks/use-document-type-filters.js";
import { v4 as uuidv4 } from "uuid";
import { useParams, useLocation } from "react-router-dom";

let hidden, visibilityChange;

if (typeof document.hidden !== "undefined") {
    hidden = "hidden";
    visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
}


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function GanttView({ documentTypeId, space, module, category, projectTypeId, projectsSlug, dateStart = "start", dateEnd = "end", projectDateStart = "start", projectDateEnd = "end", filtersEnabled = true, currentDocumentTypeId = null, queryFilterId = null }) {
    const [filters, setFilters] = useState(null);
    const { isPending, documents, reload, fields, columns, isLoadedFields } = useDocumentsList(documentTypeId, 1, 10000, "id", 1, filters, null, queryFilterId);
    const sortColumn = "id";
    const sortDir = 1;
    const columnsVisible = ["name"];
    const query = useQuery();
    const queryFilterIdUrl = useMemo(() => query.get("filter"), [query]);
    const prevFilterID = useRef(query.get("filter"));
    const [page, setPage] = useState(1);
    const { isPending: isPendingProjects, documents: documentsProjects, reload: reloadProjects } = useDocumentsList(projectTypeId, 1, 10000, "id", 1);
    const [locale, setLocale] = React.useState(i18next.language?.slice(0, 2) ?? "en");
    const { run, isPending: isSaving } = useAsync();
    const [tasks, setTasks] = useState([]);
    const [toEdit, setToEdit] = useState(null);
    const [view, setView] = useState("Week");
    const [drawerId, setDrawerId] = useState(null);
    const [drawerSlug, setDrawerSlug] = useState(space);
    const { data: documentTypeFilters, isPending: isPendingFilters } = useDocumentTypeFilters(currentDocumentTypeId);
    const [filtersOpen, setFiltersOpen] = useState(false);
    const { data: availableDocumentTypes, isLoaded: isLoadedDocumentTypes } = useAvailableDocumentTypes(documentTypeId);
    const { statuses } = useDocumentStatuses(documentTypeId);
    const [sidebarVisible, setSidebarVisible] = useState(() => {
        const value = localStorage.getItem("menubar.visible");
        if (!value) return true;
        return value === "true";
    });
    const [visibilityStatus, setVisibilityStatus] = React.useState(document[hidden]);
    const { t } = useTranslation();


    useEffect(() => {
        const interval = setInterval(() => {
            let value = localStorage.getItem("menubar.visible");
            if (!value) value = "true";
            setSidebarVisible(value === "true");
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    React.useEffect(() => {
        function handleVisibilityChange() {
            setVisibilityStatus(document[hidden]);

            reload();
            reloadProjects();
        }

        document.addEventListener(visibilityChange, handleVisibilityChange);

        return () => {
            document.removeEventListener(visibilityChange, handleVisibilityChange);
        };
    }, []);

    useEffect(() => {
        if (isPending == true) return;
        if (projectTypeId && isPendingProjects == true) return;

        const newProjects = documentsProjects?.data?.map(i => {
            let startDate = i?.values?.[projectDateStart];
            let endDate = i?.values?.[projectDateEnd];

            if (startDate == "") startDate = null;
            if (endDate == "") endDate = null;

            let newTask = {
                id: "project_" + parseInt(i?.id),
                originalId: parseInt(i?.id),
                name: i?.name,
                start: setHours(new Date(Date.parse(startDate ?? new Date()))),
                end: setHours(new Date(Date.parse(endDate ?? startDate ?? new Date()))),
                progress: parseInt(i?.values?.progress ?? 0) ?? 0,
                type: "project",
                // dependencies: i?.links?.filter(l => l?.link_type_id == 7)?.map(l => l?.id) ?? [],
                hideChildren: false,
                displayOrder: (1000 * parseInt(i?.id)),
            };

            return newTask;
        }) ?? [];

        const newDocs = documents?.data?.map(i => {
            let startDate = i?.values?.[dateStart];
            let endDate = i?.values?.[dateEnd];

            if (startDate == "") startDate = null;
            if (endDate == "") endDate = null;

            let newTask = {
                id: parseInt(i?.id),
                name: i?.name,
                start: setHours(new Date(Date.parse(startDate ?? new Date()))),
                end: setHours(new Date(Date.parse(endDate ?? startDate ?? new Date()))),
                progress: parseInt(i?.values?.progress ?? 0) ?? 0,
                type: i?.values?.type ?? "task",
                dependencies: i?.links?.filter(l => l?.link_type_id == 6)?.map(l => l?.id) ?? [],
                hideChildren: false,
                displayOrder: (i?.values?.project ? (1000 * parseInt(i?.values.project) + 1) : 0),
            };

            if (i?.values?.project && newTask.type != "project") {
                newTask.project = "project_" + parseInt(i?.values?.project);
            }

            return newTask;
        }) ?? [];

        const foundProjects = newDocs?.map(i => i?.project ?? null);
        const visibleProjects = newProjects?.filter(p => foundProjects?.includes(p?.id));

        setTasks([
            ...visibleProjects,
            ...newDocs,
        ]);


    }, [isPending, isPendingProjects, projectTypeId]);



    const fieldsMap = fields?.reduce((acc, i) => {
        acc[i?.name] = i?.label;
        return acc;
    }, {});

    useEffect(() => {
        if (isPendingFilters) return;
        if (!isLoadedFields) return;
        if (!isLoadedDocumentTypes) return;

        if (!queryFilterId) {
            if (filters != null && Object.keys(filters ?? {}).length > 0) {
                setFilters({});
            }
            return;
        }

        if (!documentTypeFilters[queryFilterId]) {
            if (filters != null && Object.keys(filters ?? {}).length > 0) {
                setFilters({});
            }
            return;
        }

        const filt = {
            filters: documentTypeFilters[queryFilterId].filters.map(f => ({
                ...f,
                id: uuidv4(),
                type: fieldsMap[f?.name],
            })),
            type: documentTypeFilters[queryFilterId].condition,
            sortColumn: documentTypeFilters[queryFilterId].sort_column ?? undefined,
            sortDir: documentTypeFilters[queryFilterId].sort_dir ?? undefined,
            columnsVisible: documentTypeFilters[queryFilterId].columns_visible ?? undefined,
        };

        filt.filters = filt.filters?.map(f => {
            try {
                const val = JSON.parse(f?.value);

                if (typeof val !== "object" || val == null) return f;

                return {
                    ...f,
                    name: parseInt(f?.name),
                    column: parseInt(f?.column),
                    value: val,
                    type: "document_type"
                };
            } catch (e) {
                return f;
            }
        }) || [];


        // if (filt?.sortColumn) setSortColumn(filt?.sortColumn);
        // if (filt?.sortDir) setSortDir(filt?.sortDir);
        // if (filt?.columnsVisible && !window.localStorage.getItem(localStorageKey)) setColumnsVisible(filt?.columnsVisible);

        setFilters(filt);
    }, [isLoadedFields, isLoadedDocumentTypes, queryFilterId, documentTypeFilters, isPendingFilters]);

    useEffect(() => {
        if (isPending) return;

        if (prevFilterID.current != queryFilterIdUrl) {
            console.log("queryFilterIdUrl", queryFilterIdUrl, prevFilterID.current);
            window.location.reload();

        }



        prevFilterID.current = queryFilterIdUrl;
    }, [queryFilterIdUrl, isPending]);



    function onClick(task) {
        setDrawerId(task?.originalId ?? task.id);
        setDrawerSlug(task?.type == "project" ? projectsSlug : space);
    }

    function handleExpanderClick(task) {
        setTasks(tasks.map(t => (t.id === task.id ? task : t)));
    }

    function onDateChange(task) {
        let newTasks = tasks.map(t => {
            if (t.id == task.id) {
                return task;
            }

            return t;
        });

        if (task.project) {
            const [start, end] = getStartEndDateForProject(newTasks, task.project);
            const project = newTasks[newTasks.findIndex(t => t.id === task.project)];
            if (
                project.start.getTime() !== start.getTime() ||
                project.end.getTime() !== end.getTime()
            ) {
                const changedProject = { ...project, start, end };
                newTasks = newTasks.map(t =>
                    t.id === task.project ? changedProject : t
                );
            }
        }

        setTasks(newTasks);

        if (task.project) {
            const project = newTasks[newTasks.findIndex(t => t.id === task.project)];
            const start_date = format(project.start, "yyyy-MM-dd");
            const end_date = format(project.end, "yyyy-MM-dd");

            run(apiClient(`workflow/document/${project?.originalId}`, {
                method: "PATCH", data: {
                    start: start_date,
                    end: end_date,
                }
            }))
                .catch(() => { })
                .finally(() => {
                    const start_date = format(task.start, "yyyy-MM-dd");
                    const end_date = format(task.end, "yyyy-MM-dd");

                    run(apiClient(`workflow/document/${task?.originalId ?? task.id}`, {
                        method: "PATCH", data: {
                            [projectDateStart]: start_date,
                            [projectDateEnd]: end_date,
                        }
                    }))
                        .then(() => toast.success("Daty zostały zmienione"))
                        .catch(error => {
                            toast.error("Nie udało się zmienić dat: " + error?.message);
                        }).finally(() => {
                            reload();
                            reloadProjects();
                        });
                });

        } else {
            const start_date = format(task.start, "yyyy-MM-dd");
            const end_date = format(task.end, "yyyy-MM-dd");

            run(apiClient(`workflow/document/${task?.originalId ?? task.id}`, {
                method: "PATCH", data: {
                    [dateStart]: start_date,
                    [dateEnd]: end_date,
                }
            }))
                .then(() => toast.success("Daty zostały zmienione"))
                .catch(error => {
                    toast.error("Nie udało się zmienić dat: " + error?.message);
                }).finally(() => {
                    reload();
                    reloadProjects();
                });
        }

    }

    function onProgressChange(task) {
        setTasks(allTasks => allTasks.map(t => {
            if (t.id == task.id) {
                return task;
            }

            return t;
        }));


        run(apiClient(`workflow/document/${task?.originalId ?? task.id}`, {
            method: "PATCH", data: {
                progress: task.progress,
            }
        }))
            .then(() => toast.success("Postęp został zaktualizowany"))
            .catch(error => {
                toast.error("Nie udało się zaktualizować postępu: " + error?.message);
                reload();
                reloadProjects();
            });
    }

    if (isPending || (projectTypeId && isPendingProjects)) {
        return <Spinner />;
    }

    // if (tasks?.length == 0) {
    //     return <EmptyView />;
    // }


    return <div style={{ maxWidth: `calc(100vw - ${sidebarVisible ? "320px" : "90px"})`, opacity: isPending ? "0.5" : "1.0", overflowX: "auto" }}>
        <div style={{ display: "flex", marginBottom: "10px" }}>
            <div>
                {filtersEnabled && <Button onClick={() => setFiltersOpen(value => !value)}>
                    <span>{t("documents_filters")} <Badge>{filters?.filters?.length || 0}</Badge></span>
                </Button>}

            </div>
            <div style={{ flex: 1 }}></div>
            <div style={{ width: "200px" }}>
                <Select

                    options={[
                        { label: "Dzień", value: "Day" },
                        { label: "Tydzień", value: "Week" },
                        { label: "Miesiąc", value: "Month" },
                        { label: "Rok", value: "Year" },
                    ]}
                    value={{
                        label: valueText(view),
                        value: view,
                    }}
                    onChange={e => setView(e.value)}
                />
            </div>
        </div>
        {filtersEnabled && filtersOpen && <FiltersArea>
            <Filters documentTypeId={documentTypeId}
                saveFiltersDocumentTypeId={currentDocumentTypeId}
                showOnlyTrashed={false}
                onCancel={() => setFiltersOpen(false)}
                onHide={() => setFiltersOpen(false)}
                initialFilters={filters?.filters}
                initialLinks={filters?.links}
                trashed={false}
                setTrashed={() => { }}
                initialType={filters?.type}
                onAccept={e => {
                    setPage(1);
                    setFilters(e);
                }}
                statuses={statuses}
                sortColumn={sortColumn}
                sortDir={sortDir}
                columnsVisible={columnsVisible}
                columns={[
                    { id: "name", label: fields?.find(i => i?.name === "name")?.label ?? t("column_name"), name: "name", type: "text" },
                    { id: "users", label: t("column_users"), name: "users", type: "users" },
                    ...columns,
                    ...availableDocumentTypes?.map(dt => ({
                        id: dt.value, label: dt.label, name: dt.value, type: "document_type",
                    })) || [],
                ]} />
        </FiltersArea>}
        {tasks?.length > 0 && <>
            <Gantt
                tasks={tasks}
                locale={locale}
                onDateChange={onDateChange}
                onProgressChange={onProgressChange}
                onDoubleClick={onClick}
                onExpanderClick={handleExpanderClick}
                viewMode={view}
                TaskListHeader={props => <TaskListHeaderDefault fieldsMap={fieldsMap} headerHeight={props.headerHeight} fontFamily={props.fontFamily} fontSize={props.fontSize} rowWidth={props.rowWidth} dateStart={dateStart} dateEnd={dateEnd} />}
            />
        </>}
        {tasks?.length == 0 && <EmptyView />}
        {toEdit && <EditEventModal
            documentTypeId={documentTypeId}
            documentLinkTypeId={documentTypeId}
            open={true}
            startDate={toEdit.start}
            endDate={toEdit.end}
            eventId={toEdit.id}
            resourceId={documentTypeId}
            onClose={() => setToEdit(false)}
            onEdit={() => {
                setToEdit(false);
                reload();
                reloadProjects();
                toast.success("Dokument został zapisany");
            }} />}
        {drawerId && <Drawer id={drawerId} space={drawerSlug ?? space} module={module} category={category} onReload={() => {
            reload();
            reloadProjects();
        }} onClose={() => {
            setDrawerId(null);
            reload();
            reloadProjects();
        }} />}

    </div>;
}


const EmptyView = () => {
    const { t } = useTranslation();

    return <EmptyViewWrapper>
        {t("dashboard_empty")}
    </EmptyViewWrapper>;
};

const EmptyViewWrapper = styled.div`
    border: 1px solid #eee;
    padding: 10px;
`;

function setHours(date) {
    date.setHours(12);
    return date;
}

function valueText(value) {
    switch (value) {
        case "Week":
            return "Tydzień";
        case "Day":
            return "Dzień";
        case "Month":
            return "Miesiąc";
        case "Year":
            return "Rok";
    }
}

function getStartEndDateForProject(tasks, projectId) {
    const projectTasks = tasks.filter(t => t.project === projectId);
    let start = projectTasks?.[0]?.start ?? new Date();
    let end = projectTasks?.[0]?.end ?? new Date();

    for (let i = 0; i < projectTasks.length; i++) {
        const task = projectTasks[i];
        if (start.getTime() > task.start.getTime()) {
            start = task.start;
        }
        if (end.getTime() < task.end.getTime()) {
            end = task.end;
        }
    }
    return [start, end];
}


const TaskListHeaderDefault = ({ headerHeight, fontFamily, fontSize, rowWidth, fieldsMap, dateStart, dateEnd }) => {
    return (
        <div
            className={styles.ganttTable}
            style={{
                fontFamily: fontFamily,
                fontSize: fontSize,
            }}
        >
            <div
                className={styles.ganttTable_Header}
                style={{
                    height: headerHeight - 2,
                }}
            >
                <div
                    className={styles.ganttTable_HeaderItem}
                    style={{
                        minWidth: rowWidth,
                    }}
                >
                    &nbsp;{fieldsMap["name"] ?? "-"}
                </div>
                <div
                    className={styles.ganttTable_HeaderSeparator}
                    style={{
                        height: headerHeight * 0.5,
                        marginTop: headerHeight * 0.2,
                    }}
                />
                <div
                    className={styles.ganttTable_HeaderItem}
                    style={{
                        minWidth: rowWidth,
                    }}
                >
                    &nbsp;{fieldsMap[dateStart] ?? "-"}
                </div>
                <div
                    className={styles.ganttTable_HeaderSeparator}
                    style={{
                        height: headerHeight * 0.5,
                        marginTop: headerHeight * 0.25,
                    }}
                />
                <div
                    className={styles.ganttTable_HeaderItem}
                    style={{
                        minWidth: rowWidth,
                    }}
                >
                    &nbsp;{fieldsMap[dateEnd] ?? "-"}
                </div>
            </div>
        </div>
    );
};