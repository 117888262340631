import React, { useEffect, useRef, useState, useCallback } from "react";
import { AsyncSelect } from "@atlaskit/select";
import apiClient from "../../api/api-client";
import styled from "styled-components";
import { Link } from "react-router-dom";
import CreateDocumentModal from "../documents/create-document-modal";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import Select from "@atlaskit/select";
import useDocument from "../../hooks/use-document";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import useDocumentsList from "../../hooks/use-documents-list";
import useAsync from "../../hooks/use-async";
import { AddNewDocumentDrawer } from "./search-documents-by-type";

function SearchDocumentsByTypeMultiple({ typeId, onChange, onBlur, onSaved, value, isDisabled, isModal, filter = null, link = null, selectedDocumentId, selectedDocumentTypeId, document, autoFocus = true, direction = -1, isClearable = true, isRequired = false, filterByRootDocument = false, root = null, optionsData = {}, isInternal = false, fieldName = "", isMulti = false, rawValue = [], currentRoot = null, contextDocument }) {
    const [open, setOpen] = useState(false);
    const [allOptions, setAllOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [filters, setFilters] = useState(null);
    const selectRef = useRef();
    const [query, setQuery] = useState("");
    const [selected, setSelected] = useState([]);
    const { t } = useTranslation();
    const [addNew, setAddNew] = useState(false);
    const { data: documentTypeData, run: documentTypeRun } = useAsync();
    const { documents, reload: reloadDocuments, isPending: isPendingDocuments } = useDocumentsList(filters ? selectedDocumentTypeId : null, 1, 10000, "id", 1, filters);

    const isPendingMulti = isMulti && isPendingDocuments;

    useEffect(() => {
        documentTypeRun(apiClient(`document-types/${typeId}/show`));
    }, [typeId]);

    useEffect(() => {
        if (!value || value?.length == 0) {
            setFilters(null);
            return;
        }

        setFilters({
            type: "OR",
            filters: (value ?? []).map(id => ({
                column: "id",
                operator: "is",
                value: id,
            })),
        });
    }, [value]);

    function handleOnChange(e) {
        if (!e || e?.length == 0) {
            setFilters(null);
        } else {
            setFilters({
                type: "OR",
                filters: (e ?? []).map(doc => ({
                    column: "id",
                    operator: "is",
                    value: doc.value,
                })),
            });
        }

        if (onChange) {
            return onChange(e);
        }
    }

    // const filterMethod = React.useCallback(item => {
    //     if (!filter || !link) return true;
    //     const itemLinks = item?.data?.links?.filter(il => il?.document_type?.id === link?.filtered_by_id)?.map(il => il?.id) || [];
    //     return itemLinks?.includes(filter?.value);
    // }, [ filter, link, filter?.value ]);

    // const filterByDocumentMethod = React.useCallback(item => {
    //     if (!link?.filterByDocument) return true;
    //     const linkedDocumentIds = document?.links?.map(i => i?.linked_document_id);
    //     return linkedDocumentIds?.includes(item?.value);
    // }, [ link?.filterByDocument, document?.id ]);

    useEffect(() => {
        // console.log(selectRef?.current);
        // todo, clear async select
    }, [typeId]);

    useEffect(() => {
        // if (!query) return;
        debouncedChangeHandler(query);
    }, [filter?.value, query, typeId]);

    // useEffect(() => {
    //     setOptions(allOptions.filter(i => {
    //         if (filterMethod && filterByDocumentMethod) {
    //             return filterByDocumentMethod(i) && filterMethod(i);
    //         }
    //         if (filterMethod) return filterMethod(i);
    //         if (filterByDocumentMethod) return filterByDocumentMethod(i);
    //         return true;
    //     }));
    // }, [ filter, link ]);

    useEffect(() => {
        search(query);
    }, [typeId]);

    const search = q => {

        let params = {};
        let url = `workflow/document-type/${typeId}/documents?query=${q}&per_page=50&dir=${direction}`;
        setIsLoading(true);


        if (filter && link && filter?.value) {
            params = {
                condition: "AND",
                links: [
                    { operator: "=", document_id: filter?.value }
                ],
            };
        }

        if (link?.filterByDocument) {
            params = {
                condition: "AND",
                links: [
                    { operator: "=", document_id: document?.id }
                ],
            };
        }

        if (link?.additionalSearchField) {
            url = `workflow/document-type/${typeId}/documents?per_page=50&dir=${direction}`;
            params = {
                condition: "OR",
                filters: [
                    {
                        name: "name",
                        operator: "contains",
                        value: q,
                    },
                    {
                        name: link.additionalSearchField,
                        operator: "contains",
                        value: q,
                    },
                ]
            };
        }

        if (link?.filterByField) {
            params = {
                ...params,
                where: [
                    {
                        name: link?.filterByField?.name,
                        operator: link?.filterByField?.operator,
                        value: link?.filterByField?.value,
                    },
                ]
            };
        }

        if (optionsData?.filterByField) {
            params = {
                ...params,
                where: [
                    ...params?.where ?? [],
                    {
                        name: optionsData?.filterByField?.name,
                        operator: optionsData?.filterByField?.operator,
                        value: optionsData?.filterByField?.value,
                    },
                ]
            };
        }

        if (optionsData?.filter) {
            const dt = optionsData?.filter?.documentTypeId;
            const field = optionsData?.filter?.field;
            const saidValue = (root ?? currentRoot)?.rawValues?.find(i => i?.document_type_field_id == field)?.value;
            const saidValues = (root ?? currentRoot)?.rawValues?.filter(i => i?.document_type_field_id == field);

            if (saidValues?.length === 0) {
                setAllOptions([]);
                setOptions([]);
                setIsLoading(false);
                return;
            }

            params = {
                ...params,
                linksCondition: "or",
                links: saidValues?.map(sv => ({
                    operator: "=", document_id: sv?.value,
                })),
                // links: [
                //     { operator: "=", document_id: saidValue },
                // ],
            };
        }


        if (root?.id && filterByRootDocument) {
            params = {
                ...params,
                condition: "AND",
                links: [
                    { operator: "=", document_id: root?.id }
                ],
            };
        }

        if (contextDocument?.id && optionsData?.filterByParentDocument) {
            params = {
                ...params,
                condition: "AND",
                links: [
                    { operator: "=", document_id: contextDocument?.id }
                ],
            };
        }

        apiClient(url, { method: "POST", data: params })
            .then(response => {
                const opt = response?.data?.map(i => {
                    const label = i.values?.find(a => a.name == "name")?.value;
                    const additionalField = link?.additionalSearchField ? i.values?.find(a => a.name == link?.additionalSearchField)?.value : null;

                    return ({
                        label: additionalField ? `${additionalField} - ${label}` : label,
                        value: i.id,
                        data: i,
                    });
                });

                setAllOptions([...opt]);
                setOptions([...opt]);

                // setOptions(opt.filter(i => {
                //     if (filterMethod && filterByDocumentMethod) {
                //         return filterByDocumentMethod(i) && filterMethod(i);
                //     }
                //     if (filterMethod) return filterMethod(i);
                //     if (filterByDocumentMethod) return filterByDocumentMethod(i);
                //     return true;
                // }));

                return Promise.resolve(opt);
            })
            .catch(() => {
                setOptions([]);
                Promise.resolve([]);
            })
            .finally(() => setIsLoading(false));
    };


    const debouncedChangeHandler = useCallback(debounce(search, 500), [filter?.value, typeId]);

    return <Wrapper>
        <SelectWrapper>
            <Select autoFocus={autoFocus}
                // loadOptions={search} 
                value={filters ? documents?.data?.map(doc => ({
                    value: doc?.id,
                    label: doc?.values?.name,
                    data: doc,
                })) : []}
                options={options}
                onChange={handleOnChange}
                onBlur={onBlur}
                inputValue={query}
                onInputChange={setQuery}
                onFocus={() => search(query)}
                isDisabled={isDisabled || isPendingMulti}
                isLoading={isLoading || isPendingMulti}
                isRequired={isRequired}
                isClearable={isClearable}
                filterOption={() => true}
                isMulti={true}
                // defaultOptions={options} 
                ref={selectRef} />
        </SelectWrapper>
        {
            documentTypeData?.creatable && <Row>
                <AddNewWrapper>
                    <Button appearance="primary" onClick={() => {
                        setAddNew(true);
                    }}>
                        +
                    </Button>
                </AddNewWrapper>
            </Row>
        }
        {/* {isInternal && <input type="text" name={fieldName} value={internalValue?.value} />} */}
        {/* {isModal && <Row>
            <AddNewWrapper>
                <Link to="" onClick={e => {
                    e.preventDefault();
                    setOpen(true);
                }}>
                    <Button appearance="default">
                        {t("search_documents_by_type_add")}
                    </Button>
                </Link>
            </AddNewWrapper>
            <Spacer />
        </Row>}
        {open && <CreateDocumentModal documentTypeId={typeId} open={open} onClose={() => setOpen(false)} onSubmit={e => {
            setOpen(false);
            onChange({
                label: e.name,
                value: e.id
            });
        }} />} */}
        {addNew && <AddNewDocumentDrawer
            documentTypeId={typeId}
            space={documentTypeData?.space}
            linkWithDocumentFromField={optionsData?.linkWithDocumentFromField}
            root={root}
            onClose={
                (res) => {
                    setAddNew(false);

                    if (res) {
                        if (onSaved) {
                            onSaved([
                                ...(value ?? []),
                                res?.value,
                            ])
                                .then(() => {

                                });
                        }
                    }
                }
            }
        />}
    </Wrapper>;
}

function findLabel(document, fields) {
    const field = fields?.find(i => i?.name === "name");

    if (!field) return "";

    return document?.values?.[field?.id] ?? "";
}


export default SearchDocumentsByTypeMultiple;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const SelectWrapper = styled.div`
    width: 100%;
`;

const Row = styled.div`
    display: flex;
`;

const Spacer = styled.div`
    flex-grow: 1;
`;

const AddNewWrapper = styled.div`
    padding: 10px 5px;
    font-weight: bold;
    padding-left: 20px;
`;