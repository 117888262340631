import React, { useState, useCallback, useMemo } from "react";
import { Checkbox } from "@atlaskit/checkbox";
import toBoolean from "./to-boolean";
import FormTable from "../components/forms/form-table";
import DOMPurify from "dompurify";
import DocumentTable from "../components/documents/document-table";
import DisplayDocumentByType, { DisplayDocumentsByType, DocumentFrame, DocumentLinkRow, DocumentLinkWrapper } from "../components/documents/display-document-by-type";
import Spinner from "../components/ui/spinner";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AvatarGroup from "@atlaskit/avatar-group";
import Avatar, { AvatarItem } from "@atlaskit/avatar";
import FileItem from "../components/files/file-item";
import FilesField from "../components/files/files-field";


export default function CellValue({ value, document, field, editable = false, related = null, allUsers = [] }) {
    const { t } = useTranslation();
    const defaultValue = editable ? <EditablePlaceholder>{t("cell_value_placeholder")}</EditablePlaceholder> : "-";

    const Wysywig = ({ value }) => {
        if (!value) return defaultValue;

        const html = DOMPurify.sanitize(value);
        // eslint-disable-next-line react/react-in-jsx-scope
        return <div dangerouslySetInnerHTML={{ __html: html }} />;
    };

    if (toBoolean(field?.workflow) && !value) {
        return <Placeholder>
            <Spinner size="xsmall" />
        </Placeholder>;
    }

    if (field.field.type === "checkbox") return <Checkbox
        isChecked={toBoolean(value)} label={field?.label ?? field?.options?.label}
    />;

    if (field.field.type === "wysywig") return <Wysywig value={value} />;

    if (field.field.type === "select") {
        const label = field.options
            .map(i => ({ label: i.name, value: i.value }))
            .find(i => i.value == value)
            ?.label;

        return <span>{label || defaultValue}</span>;
    }

    if (field.field.type === "multiselect") {
        const values = document?.rawValues?.filter(i => i?.document_type_field_id === field?.id) || [];
        const labels = values
            ?.map(i => {
                const option = field?.options?.find(o => o?.value === i?.value);
                return { label: option?.name, value: option?.value };
            })
            ?.map(i => i?.label)
            ?.join(", ");

        if (document?.mappedValues) {
            const values = field?.options?.filter(o => document?.mappedValues?.[field.name]?.includes(o?.value));
            const labels = values
                ?.map(i => i?.name)
                ?.join(", ");

            return <span>{labels || "-"}</span>;
        }

        return <span>{labels || "-"}</span>;
    }

    if (field.field.type === "table") {
        return <FormTable table_column={field.table_column} label={field.label} editable={false} columns_values={document.columns_values} />;
    }

    if (field.field.type === "user") {
        const user = (allUsers ?? [])?.find(u => parseInt(u?.id) == parseInt(value));
        if (!user) return "-";

        return <div>
            <AvatarItem
                key={user?.id}
                avatar={<Avatar size="small" src={user?.avatar_url} />}
                primaryText={user.first_name + " " + user?.last_name}
            />
        </div>;
    }

    if (field.field.type === "users") {
        const values = document?.rawValues?.filter(i => i?.document_type_field_id === field?.id)?.map(i => parseInt(i?.value)) || [];
        const users = allUsers
            ?.filter(user => (values ?? [])?.includes(user?.id));
        // ?.map(user => ({
        //     email: user?.email,
        //     key: user?.id,
        //     name: `${user?.first_name} ${user?.last_name}`,
        //     href: "#",
        //     src: user?.avatar_url,
        // }));
        if (!users || users?.length == 0) return "-";

        return <div>
            {users?.map(user => (
                <div key={user?.id}>
                    <AvatarItem
                        key={user?.id}
                        avatar={<Avatar size="small" src={user?.avatar_url} />}
                        primaryText={user.first_name + " " + user?.last_name}
                    />
                </div>
            ))}
            {/* <AvatarGroup appearance="stack" maxCount={3} size="small" data={users} /> */}
        </div>;
    }

    if (field.field.type === "files") {
        const rawOptions = field.options?.[0];
        if (!rawOptions) return "-";
        let optionsData = {};
        try {
            optionsData = JSON.parse(rawOptions);
        } catch (e) {
            //
        }

        const ids = document?.rawValues
            ?.filter(i => i?.document_type_field_id === field?.id)
            ?.map(i => {
                try {
                    return JSON.parse(i?.value);
                } catch (err) {
                    return {};
                }
            })
            ?.map(i => parseInt(i?.id)) || [];
        const files = document?.files
            ?.filter(file => (ids ?? [])?.includes(file?.id));

        return <div>
            {files?.length == 0 && <div>-</div>}
            {files.map(f => (<FileItem
                documentId={document?.id} isSingle={optionsData?.isSingle} file={f} key={f.id} editMode={editable} editable={editable} onRemoved={() => { }} />))}
        </div>;
    }

    if (field.field.type === "document-type") {
        const rawOptions = field.options?.[0];
        if (!rawOptions) return "-";
        let optionsData = {};
        try {
            optionsData = JSON.parse(rawOptions);
        } catch (e) {
            return "-";
        }
        if (value === "") return "-";

        const relatedDoc = related?.find(i => parseInt(i?.id) === parseInt(value));

        if (relatedDoc) {
            return <DocumentLinkWrapper margin={false}>
                <Link to={`/${relatedDoc?.uri}/${relatedDoc?.id}`}>
                    <DocumentLinkRow height="20px">
                        {relatedDoc?.name}
                    </DocumentLinkRow>
                </Link>
            </DocumentLinkWrapper>;
        }

        return <DisplayDocumentByType id={parseInt(value)} documentTypeId={optionsData?.documentTypeId} />;
    }

    if (field.field.type === "document-type-multi") {
        const rawOptions = field.options?.[0];
        if (!rawOptions) return "-";
        let optionsData = {};
        try {
            optionsData = JSON.parse(rawOptions);
        } catch (e) {
            return "-";
        }

        if (document?.mappedValues) {
            const ids = (document?.mappedValues?.[field.name] ?? []).map(i => parseInt(i));

            if (ids?.length == 0) return "-";

            const relatedDocs = related?.filter(i => ids?.includes(parseInt(i?.id)));
            if (relatedDocs?.length == 0) return "-";

            return <MultiDocumentFrame height="20px" >
                <DocumentLinkWrapper>
                    {relatedDocs?.map(relatedDoc => (
                        <Link to={`/${relatedDoc?.uri}/${relatedDoc?.id}`} key={relatedDoc?.id}>
                            <DocumentLinkRow height="20px">
                                {relatedDoc?.name}
                            </DocumentLinkRow>
                        </Link>
                    ))}
                </DocumentLinkWrapper>
            </MultiDocumentFrame >;
            // 
            // return <DisplayDocumentsByType ids={ids} documentTypeId={optionsData?.documentTypeId} optionsData={optionsData} />;
        }


        if (related) {
            const values = useMemo(() => document?.rawValues?.filter(i => i?.document_type_field_id === field?.id) || [], [document?.rawValues]);
            const ids = useMemo(() => (values?.map(v => parseInt(v?.value))?.filter(v => v != "" && v != null)) ?? [], [values]);
            const relatedDocs = related?.filter(i => ids?.includes(parseInt(i?.id)));

            if (values?.length == 0) return "-";
            if (relatedDocs?.length == 0) return "-";
            // if (relatedDocs?.length > 0) {
            return <MultiDocumentFrame height="20px" >
                <DocumentLinkWrapper>
                    {relatedDocs?.map(relatedDoc => (
                        <Link to={`/${relatedDoc?.uri}/${relatedDoc?.id}`} key={relatedDoc?.id}>
                            <DocumentLinkRow height="20px">
                                {relatedDoc?.name}
                            </DocumentLinkRow>
                        </Link>
                    ))}
                </DocumentLinkWrapper>
            </MultiDocumentFrame >;
        }
        // }
        const values = useMemo(() => document?.rawValues?.filter(i => i?.document_type_field_id === field?.id) || [], [document?.rawValues]);
        const ids = useMemo(() => (values?.map(v => parseInt(v?.value))?.filter(v => v != "" && v != null)) ?? [], [values]);

        return <DisplayDocumentsByType ids={ids} documentTypeId={optionsData?.documentTypeId} optionsData={optionsData} />;

    }

    if (field.field.type === "list") {
        const rawOptions = field.options?.[0];
        if (!rawOptions) return "-";
        let optionsData = {};
        try {
            optionsData = JSON.parse(rawOptions);
        } catch (e) {
            return "-";
        }

        return <DocumentTable
            document={document}
            xId={optionsData?.document_type_id}
            name={optionsData?.label}
            summary={optionsData?.summary}
            editable={false}
            document_links={optionsData?.links}
            columns={optionsData?.columns}
        />;
    }

    const rawOptions = field.options?.[0];
    let optionsData = {};
    try {
        optionsData = JSON.parse(rawOptions);
    } catch (e) {
        //
    }

    if (optionsData?.format === "url" && value?.length > 0) {
        return <div>
            <a href={value} target="_blank" rel="noreferrer">{value ?? "-"}</a>
        </div>;
    } else if (optionsData?.format === "email" && value?.length > 0) {
        return <div>
            <a href={"mailto:" + value} target="_blank" rel="noreferrer">{value ?? "-"}</a>
        </div>;
    } else if (optionsData?.format === "currency" && value?.length > 0) {
        return <div>
            {parseCurrency(value, optionsData?.currency)}
        </div>;
    } else if (optionsData?.format === "number" && value?.length > 0) {
        return <div>
            {value ? new Intl.NumberFormat("pl-PL").format((value ?? "")).replaceAll(".", ",") : "-"}
        </div>;
    } else if (optionsData?.format === "tel" && value?.length > 0) {
        return <div>
            <a href={"tel:" + value} target="_blank" rel="noreferrer">{value ?? "-"}</a>
        </div>;
    }

    if (field.field.type === "password") {
        return <div>-</div>;
    }

    if (field.field.type === "number" || field.field.type === "float") {
        return <div>
            {value ? new Intl.NumberFormat("pl-PL").format((value ?? "")).replaceAll(".", ",") : "-"}
        </div>;
    }

    if (value?.length === 0) {
        return <div>{defaultValue}</div>;
    }

    if (field.field.type === "textarea") {
        return <TextareaValue>{value ?? defaultValue}</TextareaValue>;
    }

    return <div>{value ?? defaultValue}</div>;
}



const SpinnerWrapper = styled.div`
    text-align: left;
`;

const EditablePlaceholder = styled.small`
    opacity: 0.6;
    font-style: italic;
`;

const Placeholder = styled.div`
    width: 100%;
    height: 25px;
    border: 1px solid #eee;
    border-radius: 5px;
    box-shadow: 0px 2px 1px 0px #eee;
    padding: 5px;
    margin: 7px 0;
    background: rgba(0,0,0,0.005);
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: -150px;
        top: 0;
        height: 100%;
        width: 150px;
        background: linear-gradient(to right, transparent 0%, red 50%, transparent 100%);
        animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }
`;


const TextareaValue = styled.div`
white-space: pre-wrap;
`;

export function parseCurrency(value, currency) {
    const parsed = parseFloat(String(value)?.replace(",", ".") ?? 0);
    const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    };

    if (isNaN(parsed)) {
        return (new Intl.NumberFormat("pl-PL", options)).format(0);
    }

    const formatted = (new Intl.NumberFormat("pl-PL", options)).format(parsed);

    return formatted;
}

const MultiDocumentFrame = styled.div`
display: block;
width: auto;
padding: 2px 5px;
margin: 0px 0;
position: relative;
`;