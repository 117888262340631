import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import DocumentLayout from "../layouts/document-layout";
import styled from "styled-components";
import DashboardDocuments from "../components/dashboard/dashboard-documents";
import DashboardNotifications from "../components/dashboard/dashboard-notifications";
import Calendar from "../components/scheduler/calendar";
import Widgets from "../components/widgets/widgets";
import useModules from "../hooks/use-modules";
import { useTranslation } from "react-i18next";
import Spinner from "../components/ui/spinner";
import title from "../title";
import GanttView from "../components/gantt/gantt-view";

function ModulePage() {
    const { module } = useParams();
    const { modules, isPending } = useModules();
    const currentModule = modules?.find(i => i?.slug === module?.toLowerCase());
    const config = currentModule?.config ?? {};

    return <>
        <Helmet>
            <title>{config?.title ?? `${module.toUpperCase()} - ${title}`}</title>
        </Helmet>
        <DocumentLayout>
            <Header>{config?.heading ?? currentModule?.name?.toUpperCase()}</Header>
            {
                isPending
                    ? <Spinner />
                    : <ModuleView config={config} module={module} />
            }
        </DocumentLayout>
    </>;
}

function ModuleView({ config, module }) {
    const { t } = useTranslation();

    if (config?.view === "gantt") {
        return <GanttView {...config} filtersEnabled />;
    }

    if (config?.view === "calendar") {
        return <div>
            <Calendar
                xId={config?.xId}
                yId={config?.yId}
                module={module}
                columns={config?.columns ?? []}
                colorField={config?.colorField}
                bgField={config?.bgField}
                colors={config?.colors ?? {}}
                headerDocumentId={config?.headerDocumentId}
                onlyEditUserId={config?.onlyEditUserId ?? false}
                xFilters={config?.xFilters ?? null}
                yFilters={config?.yFilters ?? null}
                userEdit={config?.userEdit ?? []}
                category={config?.categorySlug}
                space={config?.spaceSlug} />
            {config?.widgets && <Widgets widgets={config?.widgets} module={module} />}
        </div>;
    }

    if (config?.widgets) {
        return <Widgets widgets={config?.widgets} module={module} />;
    }

    return <Row>
        <Left>
            <Wrapper>
                <h4>{t("dashboard_assigned")}</h4>
                <DashboardDocuments module={module} />
            </Wrapper>
        </Left>
        <Right>
            <Wrapper>
                <h4>{t("dashboard_notifications")}</h4>
                <DashboardNotifications module={module} />
            </Wrapper>
        </Right>
    </Row>;
}

export default ModulePage;

const Row = styled.div`
    @media (min-width: 1300px) {
        display: flex;
    }
`;

const Left = styled.div`
@media (min-width: 1300px) {
    width: 100%;
}
`;

const Right = styled.div`
@media (min-width: 1300px) {
    width: 80%;
}
`;

const Wrapper = styled.div`
    padding: 20px;
    margin: 20px;
    background: white;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.2);
    border-radius: 5px;
    border-top: 4px solid #2684ff;

    > h4 {
        margin-bottom: 20px;
    }
`;

const Header = styled.h2`
    padding-top: 20px;
`;