import React, { useState } from "react";
import styled from "styled-components";
import useDocumentLinkTypes from "../../hooks/use-document-link-types";
import Spinner from "../ui/spinner";
import EmptyState from "@atlaskit/empty-state";
import Button from "@atlaskit/button";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import LinkDocumentForm from "./link-document-form";
import { Link } from "react-router-dom";
import spacePath from "../../utils/space-path";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import { toast } from "react-toastify";
import apiClient from "../../api/api-client";
import useGlobalConfig from "../../hooks/use-global-config";
import { useTranslation } from "react-i18next";
import ArrowDownIcon from "@atlaskit/icon/glyph/arrow-down";
import ArrowUpIcon from "@atlaskit/icon/glyph/arrow-up";
import EditorHorizontalRuleIcon from "@atlaskit/icon/glyph/editor/horizontal-rule";
import QueuesIcon from "@atlaskit/icon/glyph/queues";
import FileIcon from "@atlaskit/icon/glyph/file";
import useDocument from "../../hooks/use-document";

function LinkedDocuments({ document, onRefresh, onDrawerClose }) {
    const { t } = useTranslation();
    const { types, isPending } = useDocumentLinkTypes();
    const [addFormOpen, setAddFormOpen] = useState(false);
    const { config: typesMapConfig } = useGlobalConfig("types_map");
    const { data: linkTypesData } = useDocumentLinkTypes();
    const linkTypes = linkTypesData?.map(i => ({ value: i.id, label: i.name })) || [];
    const { document: linkableDocument, fields: linkableFields } = useDocument(document?.id, document?.document_type_id);

    if (isPending) {
        return <Spinner />;
    }

    let allGroups = {};

    document.links.reduce((acc, link) => {
        const linkData = {
            name: link?.linked_document?.values?.find(i => i?.document_type_field?.name === "name")?.value,
            ...link.linked_document,
            link_id: link.id,
            linked_id: link?.linked_document?.id,
        };

        allGroups[link.link_type_id] = allGroups[link.link_type_id] ? [
            ...allGroups[link.link_type_id],
            linkData,
        ] : [linkData];
        return acc;
    }, {});

    return <Wrapper>
        <Heading>
            <h4>
                {t("linked_heading")}
            </h4>
            <Button iconBefore={<EditorAddIcon />} onClick={() => setAddFormOpen(true)}></Button>
        </Heading>
        {addFormOpen && <LinkDocumentForm document={document} onCancel={() => setAddFormOpen(false)} onSuccess={() => {
            setAddFormOpen(false);
            onRefresh();
        }} />}
        {document?.mapped_links?.length === 0 && !addFormOpen && <EmptyState header="" description="" primaryAction={<Button appearance="primary" onClick={() => setAddFormOpen(true)}>
            {t("linked_empty_button")}
        </Button>} />}
        {Object.keys(document?.mapped_links).length > 0 && <GroupsWrapper>
            {Object.keys(document?.mapped_links).map((group, key) => (
                <Group key={group}>
                    <GroupHeading>
                        {group}
                        <GroupHeadingCount>{` (${document?.mapped_links_count[group]})`}</GroupHeadingCount>
                        <GroupHeadingLink>
                            <Link to={`/${document?.mapped_links[group]?.[0]?.path}?document=${document?.id}`} onClick={() => {
                                onDrawerClose && onDrawerClose();
                            }}>
                                {t("linked_all_related")}
                            </Link>
                        </GroupHeadingLink>
                    </GroupHeading>
                    <GroupWrapper>
                        <GroupContent>
                            {document.mapped_links[group].map(link => (
                                <DocumentLink key={link.id}>
                                    <IconWrapper>
                                        <Icon name={link.icon} />
                                    </IconWrapper>
                                    <LinkWrapper>
                                        <Link to={`/${link.slug}`}>
                                            <LinkStyle>{link.name}</LinkStyle>
                                        </Link>
                                    </LinkWrapper>
                                    {(link?.link_type_id == 6 || link?.link_type_id == 7) &&
                                        <LinkTypeWrapper>
                                            {linkTypes?.find(i => i?.value == link?.link_type_id)?.label ?? ""}
                                        </LinkTypeWrapper>
                                    }
                                    {(link?.link_type_id == 1 || link?.link_type_id == 2 || link?.link_type_id == 4 || link?.link_type_id == 5) &&
                                        <LinkTypeWrapper>
                                            {linkTypes?.find(i => i?.value == link?.link_type_id)?.label ?? ""}
                                        </LinkTypeWrapper>
                                    }
                                    <DropdownMenu position="bottom right" triggerType="button">
                                        <DropdownItemGroup>
                                            <DropdownItem onClick={() => {
                                                apiClient(`document/${document?.id}/unlink/${link?.id}`, { method: "DELETE" })
                                                    .then(() => toast.info("Powiązanie usunięte"))
                                                    .then(() => onRefresh())
                                                    .catch(() => toast.error("Nie udało się usunąć powiązania"));
                                            }}>
                                                Usuń
                                            </DropdownItem>
                                        </DropdownItemGroup>
                                    </DropdownMenu>
                                </DocumentLink>
                            ))}
                        </GroupContent>
                    </GroupWrapper>
                </Group>
            ))}
        </GroupsWrapper>}
    </Wrapper>;
}

function Icon({ name }) {
    switch (name) {
        case "ArrowDownIcon": return <ArrowDownIcon />;
        case "ArrowUpIcon": return <ArrowUpIcon />;
        case "EditorHorizontalRuleIcon": return <EditorHorizontalRuleIcon />;
        case "QueuesIcon": return <QueuesIcon />;
        case "FileIcon": return <FileIcon />;
        default: return null;
    }
}

export default LinkedDocuments;

const Wrapper = styled.div`
margin-top: 40px;
`;

const Heading = styled.div`
    display: flex;

    &>h4 {
        flex-grow: 1;
    }
`;


const Group = styled.div`
margin: 20px 0;
`;

const GroupHeading = styled.h6`
    color: #6B778C;
    display: flex;
`;

const GroupContent = styled.ul`
box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.31) 0px 0px 1px 0px;
background: rgb(250, 251, 252) none repeat scroll 0% 0%;
border-radius: 3px;
padding: 0px;
`;

const DocumentLink = styled.div`
background: rgb(255, 255, 255) none repeat scroll 0% 0%;
border-radius: 3px;
cursor: pointer;
display: flex;
position: relative;
height: 40px;
padding: 0px 8px;
border-bottom: 1px solid rgb(235, 236, 240);
display: flex;
-moz-box-align: center;
align-items: center;
`;

const LinkWrapper = styled.div`
    flex-grow: 1;
`;

const LinkTypeWrapper = styled.div`
    padding: 0 10px;
    font-size: 12px;
    color: #ccc;
    font-weight: 600;
    text-transform: uppercase;
`;

const Category = styled.span`
color: rgb(137, 147, 164);
text-decoration: none;
outline: currentcolor none medium;
margin-left: 8px;
color: rgb(137, 147, 164);
font-weight: 600;
font-size: 12px;
text-decoration: none;
white-space: nowrap;
margin-right: 10px;
text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
`;

const Spacer = styled.div`
flex-grow: 1;
`;

const GroupWrapper = styled.div`
    margin-top: 10px;
`;

const GroupsWrapper = styled.div`
    margin-top: 20px;
    padding: 5px 25px 10px;
    background: rgba(0,0,0,0.02);
    border-radius: 5px;
`;

const LinkStyle = styled.span`
    font-weight: 600;
`;

const GroupHeadingCount = styled.div`
    opacity: 0.6;
    display: inline-block;
    margin-left: 5px;
    font-size: 1em;
`;

const GroupHeadingLink = styled.div`
    flex: 1;
    text-align: right;
`;

const IconWrapper = styled.div`
    margin-right: 5px;
`;