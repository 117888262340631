import React, { useState, useEffect } from "react";
import { useAuth } from "../../providers/auth-provider";
import styled from "styled-components";
import {
    AtlassianNavigation, ProductHome, Search,
} from "@atlaskit/atlassian-navigation";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import { NotificationsPopup } from "../notifications/notifications-popup";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/LogoErp.svg";
import { ReactComponent as LogoVseli } from "../../assets/LogoVseli.svg";
import Avatar, { AvatarItem } from "@atlaskit/avatar";
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import AppSwitcher from "./app-switcher";
import toBoolean from "../../utils/to-boolean";
import { useTranslation } from "react-i18next";
import Select from "@atlaskit/select";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import LogoUwr from "../../assets/LogoUwr.png";

const domain = window.location.hostname;
const uwrDomains = ["uwr.app.erpspace.pl", "simulation.akta.american-systems.pl"];

function Navbar({ user, logout }) {
    return <Wrapper>
        <AtlassianNavigation
            renderProductHome={() => <Home user={user} />}
            renderProfile={() => <UserProfile user={user} logout={logout} />}
            renderCreate={CreateButton}
            renderNotifications={NotificationsPopup}
            primaryItems={[
            ]}
            renderAppSwitcher={AppSwitcher}
        />
    </Wrapper>;
}

function Home({ user }) {
    return <LogoWrapper>
        <Link to="/">
            {
                domain == "app.vseli.com" || domain == "staging.vseli.com"
                    ? <LogoVseli width="80px" height="30px" style={{
                        paddingTop: "10px",
                    }} />
                    : uwrDomains.includes(domain)
                        ?
                        <img src={LogoUwr} width="30px" height="30px" style={{
                            paddingTop: "5px",
                        }} />
                        : <Logo />
            }
        </Link>
        {
            user && user?.organizations?.length > 1 && <div>
                <SelectOrganization user={user} />
            </div>
        }
    </LogoWrapper>;
}

function SelectOrganization({ user }) {
    const { t } = useTranslation();
    const { run, isPending, data } = useAsync();

    const onChange = e => {
        run(apiClient(`user/organization/${e?.value}`, { method: "POST" }))
            .then(() => {
                localStorage.removeItem("counted");
                localStorage.removeItem("counted-date");

                window.location.reload();
            })
            .catch(() => {
                toast.error(t("change_organization_error"));
            });
    };

    return <div style={{ marginLeft: "10px", width: "200px" }}>
        <Select
            isDisabled={isPending}
            value={{
                label: user?.organization?.name,
                value: user?.organization?.id,
            }}
            placeholder={t("select_organization_placeholder")}
            shouldFitContainer
            onChange={onChange}
            options={user?.organizations?.map(o => ({
                label: o?.name,
                value: o?.id,
            }))} />
    </div>;
}

function UserProfile({ user, logout }) {
    const { t } = useTranslation();
    if (!user) return null;

    const onLogout = () => {
        logout();
    };

    const currentOrganization = user?.organization;
    const userOrganizations = user?.organizations;

    const currentUserType = userOrganizations?.find(o => o?.id == currentOrganization?.id)?.pivot?.type;
    const adminable = currentUserType == "admin";

    return <>
        <DropdownMenu position="bottom right" trigger={
            <AvatarWrapper>
                <AvatarItem
                    avatar={<Avatar src={user?.avatar_url} size="small" />}
                />
            </AvatarWrapper>
        }>
            <DropdownItemGroup>
                <Link to="/profile">
                    <DropdownItem id="email">
                        {t("nav_settings")}
                    </DropdownItem>
                </Link>
                {toBoolean(user?.is_admin) && <Link to="/admin">
                    <DropdownItem id="admin">
                        {t("nav_admin")}
                    </DropdownItem>
                </Link>}
                {adminable && <Link to={`/organizations/${currentOrganization?.id}`}>
                    <DropdownItem id="organization">
                        {currentOrganization?.name}
                    </DropdownItem>
                </Link>}
                <DropdownItem id="logout" onClick={() => onLogout()}>
                    {t("nav_logout")}
                </DropdownItem>
            </DropdownItemGroup>
        </DropdownMenu>
    </>;
}

function CreateButton() {
    return null;
}

function SearchInput() {
    return <Search placeholder="Szukaj..." />;
}

export default Navbar;

const Wrapper = styled.div`
        position: fixed;
        width: 100%;
        height: 56px;
        top: 0;
        left: 0;
        z-index: 203;
    `;

const AvatarImg = styled.img`
        border-radius: 50%;
        width: 24px;
        height: 24px;
        margin-top: 5px;
`;

const AvatarWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const AvatarIconWrapper = styled.div`
    cursor: pointer;
    padding-top: 5px;
`;

const LogoWrapper = styled.div`
    margin: 10px 5px;
    display: flex;
    align-items: center;
`;

