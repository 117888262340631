import React, { useEffect, useState } from "react";
import DocumentLayout from "../layouts/document-layout";
import { Link, useParams } from "react-router-dom";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import Spinner from "../components/ui/spinner";
import { BreadcrumbsItem, BreadcrumbsStateless } from "@atlaskit/breadcrumbs";
import useSpace from "../hooks/use-space";
import PageHeader from "@atlaskit/page-header";
import spacePath, { modulePath } from "../utils/space-path";
import NewDocumentForm from "../components/documents/new-document-form";
import Form from "@atlaskit/form";
import useAsync from "../hooks/use-async";
import apiClient from "../api/api-client";
import prepareFormData from "../utils/prepare-form-data";
import { toast } from "react-toastify";
import styled from "styled-components";
import SectionMessage from "@atlaskit/section-message";
import { Helmet } from "react-helmet";
import useDocumentFields from "../hooks/use-document-fields";
import { useTranslation } from "react-i18next";
import title from "../title";

function NewPage() {
    const { t } = useTranslation();
    const { space: slug } = useParams();
    const { space, isPending, isError, forbidden } = useSpace(slug);
    const { run, isPending: isCreating, error, isError: isCreatingError } = useAsync();

    if (forbidden) {
        return <DocumentLayout>
            <h1>403</h1>
            <h3>{t("forbidden")}</h3>
        </DocumentLayout>;
    }

    if (isPending) {
        return <DocumentLayout><Spinner /></DocumentLayout>;
    }

    if (isError) {
        window.location.href = "/404";
        return null;
    }

    const handleSubmit = data => {
        run(apiClient(`workflow/document-type/${space?.document_type?.id}/document`, { data }))
            .then(response => {
                window.location.href = `${spacePath(space)}/${response.id}`;
            })
            .catch(() => {
                toast.error(t("new_error"));
            });
    };

    return <>
        <Helmet>
            <title>{`${space?.document_type?.name} - ${t("new_title")} - ${title}`}</title>
        </Helmet>
        <DocumentLayout>
            <Wrapper>
                <Left>
                    <NewPageInner
                        space={space}
                        handleSubmit={handleSubmit}
                        isCreating={isCreating}
                        error={error}
                        isCreatingError={isCreatingError}
                    />
                </Left>
            </Wrapper>
        </DocumentLayout>
    </>;
}

export default NewPage;

export function NewPageInner({ space, handleSubmit, isCreating, error, isCreatingError, contextDocument, onSuccess }) {
    const { t } = useTranslation();
    const [additionalData, setAdditionalData] = useState({});
    const { isPending: isPendingFields, fields } = useDocumentFields(space?.document_type?.id);
    const [isPendingWorkflow, setIsPendingWorkflow] = useState(false);
    const bottomButton = space?.document_type?.config?.bottomCreateButton ?? false;
    const [alreadyRun, setAlreadyRun] = useState(false);
    const { run } = useAsync();

    useEffect(() => {
        if (space?.document_type?.config?.emptyDocumentWorkflow) {
            setIsPendingWorkflow(true);
            apiClient(`document-types/${space?.document_type?.id}/empty-document-workflow`, {
                method: "POST",
                data: additionalData,
            })
                .then(res => {
                    setAdditionalData(d => ({
                        ...d,
                        ...res,
                    }));
                })
                .catch(err => {
                    setIsPendingWorkflow(false);
                })
                .finally(() => {
                    setIsPendingWorkflow(false);
                });
        }
    }, [space?.slug]);


    useEffect(() => {
        if (alreadyRun) return;

        fields?.forEach(field => {
            setAlreadyRun(true);
            let optionsData = {};
            if (field.field.type == "select" || field.field.type == "multiselect") {
                const rawOptions = field?.select_options;
                try {
                    optionsData = JSON.parse(rawOptions);
                } catch (e) {
                    console.error(e);
                }
            } else {
                const rawOptions = field?.options?.[0];
                try {
                    optionsData = JSON.parse(rawOptions);
                } catch (e) {
                    console.error(e);
                }
            }


            if (optionsData?.emptyDocumentWorkflow) {
                setIsPendingWorkflow(true);
                apiClient(`fields/${field?.id}/empty-document-workflow`, {
                    method: "POST",
                    data: additionalData,
                })
                    .then(res => {
                        setAdditionalData(d => ({
                            ...d,
                            ...res,
                        }));
                    })
                    .catch(err => {
                        setIsPendingWorkflow(false);
                    })
                    .finally(() => {
                        setIsPendingWorkflow(false);
                    });
            }
        });
    }, [fields]);

    const actionsContent = bottomButton ? null : (
        <ButtonGroup>
            <Button appearance="primary" type="submit" isLoading={isCreating}>
                {t("new_button")}
            </Button>
        </ButtonGroup >
    );
    const breadcrumbs = (
        <BreadcrumbsStateless>
            {space?.category?.module && <BreadcrumbsItem text={space.category.module?.alias} key="module" component={(props) => <Link to={modulePath(space?.category?.module)} {...props} />} />}
            {space?.category && <BreadcrumbsItem text={space.category.name} key="category" component={(props) => <Link to={modulePath(space?.category?.module)} {...props} />} />}
            {space && <BreadcrumbsItem text={space.name} key="space" component={(props) => <Link to={spacePath(space)} {...props} />} />}
            <BreadcrumbsItem text={t("new_heading")} href={`${spacePath(space)}/new`} />
        </BreadcrumbsStateless>
    );

    return <Form
        onSubmit={(e) => {
            handleSubmit(additionalData);
        }}
    >
        {({ formProps }) => (
            <form {...formProps} name="submit-form">
                <PageHeader breadcrumbs={breadcrumbs} actions={actionsContent}>
                    {space?.document_type?.name}
                </PageHeader>
                {isCreatingError && <SectionMessageWrapper>
                    <SectionMessage title={t("new_error_heading")} appearance="error">
                        <p>{error?.message || "Unexpected error"}</p>
                        {error?.errors && <ul>
                            {Object.keys(error.errors).map(key => (
                                <li key={key}>{error.errors[key]}</li>
                            ))}
                        </ul>}
                    </SectionMessage>
                </SectionMessageWrapper>}
                <NewDocumentForm documentTypeId={space?.document_type?.id}
                    fields={fields}
                    isPending={isPendingFields}
                    isDisabled={isPendingWorkflow}
                    error={error}
                    contextDocument={contextDocument}
                    data={additionalData}
                    propagateFields={(propagatingField, valuesMap) => {
                        const propagatingFieldOptions = parseOptions(propagatingField?.options);
                        const documentTypeId = propagatingFieldOptions?.documentTypeId;

                        fields.map(f => {
                            const fieldOptions = parseOptions(f?.options);

                            if (fieldOptions?.document_type?.id == documentTypeId && fieldOptions?.document_type?.name === propagatingField?.name) {
                                const fieldName = fieldOptions?.document_type?.field_name;
                                const value = valuesMap[fieldName];

                                setAdditionalData(d => ({
                                    ...d,
                                    [f?.name]: value
                                }));
                            }
                        });
                    }}
                    onBlur={(blurredField) => {
                        if (space?.document_type?.config?.newDocumentWorkflow) {
                            setIsPendingWorkflow(true);
                            apiClient(`document-types/${space?.document_type?.id}/new-document-workflow`, {
                                method: "POST",
                                data: additionalData,
                            })
                                .then(res => {
                                    setAdditionalData(d => ({
                                        ...d,
                                        ...res,
                                    }));
                                })
                                .catch(err => {
                                    setIsPendingWorkflow(false);
                                })
                                .finally(() => {
                                    setIsPendingWorkflow(false);
                                });
                        }

                        fields?.forEach(field => {
                            if (field?.id !== blurredField?.id) return;

                            let optionsData = {};
                            if (field.field.type == "select" || field.field.type == "multiselect") {
                                const rawOptions = field?.select_options;
                                try {
                                    optionsData = JSON.parse(rawOptions);
                                } catch (e) {
                                    console.error(e);
                                }
                            } else {
                                const rawOptions = field?.options?.[0];
                                try {
                                    optionsData = JSON.parse(rawOptions);
                                } catch (e) {
                                    console.error(e);
                                }
                            }


                            if (optionsData?.newDocumentWorkflow) {
                                setIsPendingWorkflow(true);
                                apiClient(`fields/${field?.id}/new-document-workflow`, {
                                    method: "POST",
                                    data: additionalData,
                                })
                                    .then(res => {
                                        setAdditionalData(d => ({
                                            ...d,
                                            ...res,
                                        }));
                                    })
                                    .catch(err => {
                                        setIsPendingWorkflow(false);
                                    })
                                    .finally(() => {
                                        setIsPendingWorkflow(false);
                                    });
                            }
                        });
                    }}
                    onChange={(field, data) => {
                        setAdditionalData(d => ({
                            ...d,
                            [field.name]: data
                        }));
                    }} />
                {bottomButton && <Wrapper style={{ marginTop: "20px" }}>
                    <FlexGrow />
                    <Button appearance="primary" type="submit" isLoading={isCreating}>
                        {t("new_button")}
                    </Button>
                </Wrapper>}
            </form>
        )}
    </Form>;
}

function parseOptions(opt) {
    const optString = opt?.[0] || "{}";

    try {
        return JSON.parse(optString);
    } catch (e) {
        return {};
    }
}

const Wrapper = styled.div`
    display: flex;
`;

const Left = styled.div`
    width: 60%;
`;

const SectionMessageWrapper = styled.div`
    margin-top: 50px;
    font-size: 13px;
`;

const FlexGrow = styled.div`
    flex-grow: 1;
`;