import Spinner from "../ui/spinner";
import React, { useState } from "react";
import "react-pivottable/pivottable.css";
import PivotTableUI from "react-pivottable/PivotTableUI";
import useDocumentsPivotList from "../../hooks/use-documents-pivot-list";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import ProgressBar from "@atlaskit/progress-bar";

const PlotlyRenderers = createPlotlyRenderers(Plot);

export default function DocumentsListPivot({ id, space, fieldNames = [], options = {
    numericFieldTypeId: 2,
    dateFieldTypeId: 4,
    selectFieldTypeId: 3,
} }) {
    const { documents, isPending, pivotDocuments, progress } = useDocumentsPivotList(id, fieldNames, options, space);
    const [state, setState] = useState(null);

    if (isPending) {
        return <>
            <ProgressBar value={progress} />
        </>;
    }

    const data = documents || [];

    return <>
        <PivotTableUI
            key={space}
            data={data}
            unusedOrientationCutoff={Infinity}
            {...state}
            onChange={e => {
                setState(e);
            }}
            dataSourceSettings={{

            }}
            renderers={Object.assign({}, TableRenderers, PlotlyRenderers)} />
    </>;
}